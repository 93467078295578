import React, { useEffect, useState } from "react";
import "./logo.css";
import { toAbsoluteUrl } from "../../../utils/CommonHelper";

function Logo({ openfrom }) {
  const [logoSrc, setLogoSrc] = useState("");

  useEffect(() => {
    const selectedTheme = localStorage.getItem("selectedTheme");
    const logoSrc =
      selectedTheme === "dark"
        ? toAbsoluteUrl("/image/logo/2a.png?125616156")
        : toAbsoluteUrl("/image/logo/2.png?47874113");
    setLogoSrc(logoSrc);
  }, []);

  return (
    <div className="d-flex align-items-center justify-content-center">
      <a href="/" className="logo d-flex align-items-center">
        {openfrom === "login" ? (
          <img
            src={logoSrc}
            alt="Logo"
            style={{ width: "25vh", height: "10vh" }}
          />
        ) : (
          <img src={logoSrc} alt="Logo" style={{ maxHeight: "26px" }} />
        )}
      </a>
    </div>
  );
}

export default Logo;
